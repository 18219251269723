// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api_url: 'https://ft67flaf6l.execute-api.ap-southeast-1.amazonaws.com/uat',
  secret_key: '3185d15c177648d980bf68a6c7179d1a', // 32 byte // abopool_xn
  secret_iv: '', // abopool_xi
  salt: '777ce50f1c8346e980deab678973511f', // abopool_xs,

  //okta config
  okta_login: true,
  okta_client_id: '0oa1qqdo3603eY4IX0h8',
  okta_issuer: 'https://amway.okta.com/oauth2/default',
  okta_redirect_uri: 'https://uat.bmk.amway.co.th/login/callback',

  //boapi config
  client_id: 'dev202204',
  client_secret: 'WHUve7gcJDox62Im',
  base_bo_api_bmk: 'https://uat.bmk.amway.co.th',
  bo_api_bmk: 'https://uat.bmk.amway.co.th/apibo/call',

  //hybris config
  hybris_api: 'https://api.uat.amway.co.th',
  hybris_client_id: 'UatBlackMarketHybris',
  hybris_client_secret: 'BlackMarket123',
  hybris_grant_type: 'client_credentials',

  //magic config
  magic_api: 'https://api-qa.amwayglobal.com',
  magic_client_id: '4j46ge8kyatu3h88tau9taf3',
  magic_client_secret: 'byXZxMX5de',
  magic_grant_type: 'client_credentials',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
