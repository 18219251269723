import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { UserService } from 'src/app/core/services/user.service';

import { navItems } from './_nav';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
})
export class DefaultLayoutComponent implements OnInit {
  public navItems = navItems;

  public perfectScrollbarConfig = {
    suppressScrollX: true,
  };

  constructor(
    private userService: UserService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    const role: any = this.authService.getUserRole();

    let items: any[] = [];
    switch (role) {
      case 'admin':
        items = [...this.navItems].filter(
          (nav) => !['Inquiry'].includes(nav.name || '')
        );
        break;
      case 'user':
        items = [...this.navItems].filter(
          (nav) => !['User', 'Inquiry', 'Config'].includes(nav.name || '')
        );
        break;
      case 'editor':
        items = [...this.navItems].filter(
          (nav) => !['User', 'Inquiry', 'Config'].includes(nav.name || '')
        );
        break;
      case 'viewer':
        items = [...this.navItems].filter(
          (nav) =>
            !['Rule Setting', 'User', 'Inquiry', 'Config'].includes(
              nav.name || ''
            )
        );
        break;
      default:
        items = [];
        break;
    }

    this.navItems = items;
  }
}
